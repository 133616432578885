import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
// COMPONENTS
// import ComplexSearchTabFirstRow from './ComplexSearchTabFirstRow/ComplexSearchTabFirstRow';
import ComplexSearchTabRowsOperationButtons from './ComplexSearchTabRowsOperationButtons/ComplexSearchTabRowsOperationButtons';
import ComplexSearchTabRow from './ComplexSearchTabRow/ComplexSearchTabRow';
import ComplexSearchTabOperationRadioButtons from './ComplexSearchTabOperationRadioButtons/ComplexSearchTabOperationRadioButtons';

const ComplexSearchTabRows = ({ urlState, setUrlState }) => {
  const { searchQuery } = urlState;

  return (
    <Grid container direction="column" spacing={2}>
      {/* <Grid item>
        <ComplexSearchTabFirstRow
          urlState={urlState}
          setUrlState={setUrlState}
        />
      </Grid> */}
      {searchQuery.map((searchObject, i) => {
        const { id } = searchObject;
        // if (i === 0) {
        //   return null;
        // }

        return (
          <Grid item key={id}>
            <Grid container direction="column">
              {i === 0 ? null : (
                <Grid item>
                  <ComplexSearchTabOperationRadioButtons
                    searchObject={searchObject}
                    urlState={urlState}
                    setUrlState={setUrlState}
                  />
                </Grid>
              )}
              <Grid item>
                <ComplexSearchTabRow
                  searchObject={searchObject}
                  urlState={urlState}
                  setUrlState={setUrlState}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid item>
        <ComplexSearchTabRowsOperationButtons
          urlState={urlState}
          setUrlState={setUrlState}
        />
      </Grid>
    </Grid>
  );
};

export default ComplexSearchTabRows;
