import React from 'react';
import { complexSearchVar } from '../../../../../../apollo/localState/mainSearch/bibSearchResultState';
// STYLE
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//HOOKS
import { useReactiveVar } from '@apollo/client';
// COMPONENTS
import SimpleSearchValueInput from '../../../SimpleSearchTab/SimpleSearchInputs/SimpleSearchValueInput/SimpleSearchValueInput';

const ComplexSearchTableDedicatedInput = ({ dedicatedInput }) => {
  const complexSearchState = useReactiveVar(complexSearchVar);
  const { title, slug } = dedicatedInput;

  const getNewDedicatedQuery = (isDedicatedQueryAdded, newValue, current) => {
    if (isDedicatedQueryAdded) {
      // VALÓS VALUE MÓDOSÍTÁS
      const newDedicatedQuery = current.dedicatedQuery.map(dedicatedObject => {
        if (dedicatedObject.searchField?.slug !== slug) {
          return dedicatedObject;
        }

        return {
          ...dedicatedObject,
          searchValue: newValue,
        };
      });

      return newDedicatedQuery;
    }

    // ABBAN AZ ESETBEN HA MÉG EDDIG NEM VOLT VALUE ADVA NEKI
    const newDedicatedQuery = [
      ...current.dedicatedQuery,
      {
        searchField: dedicatedInput,
        searchValue:
          dedicatedInput?.type === 'date-range' ? { from: '', to: '' } : '',
      },
    ];

    return newDedicatedQuery;
  };

  const dedicatedInputValueChangedHandler = newValue => {
    const current = complexSearchVar();

    const isDedicatedQueryAdded = complexSearchState.dedicatedQuery.some(
      dedicatedObject => dedicatedObject.searchField?.slug === slug
    );

    const newDedicatedQuery = getNewDedicatedQuery(
      isDedicatedQueryAdded,
      newValue,
      current
    );

    complexSearchVar({
      ...current,
      dedicatedQuery: newDedicatedQuery,
    });
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={9}>
        <SimpleSearchValueInput
          searchField={dedicatedInput}
          searchValue={
            complexSearchState.dedicatedQuery.find(
              dedicatedObject => dedicatedObject.searchField?.slug === slug
            )?.searchValue
          }
          onChange={dedicatedInputValueChangedHandler}
        />
      </Grid>
    </Grid>
  );
};

export default ComplexSearchTableDedicatedInput;
