import { bibSearchResultVar } from '../apollo/localState/mainSearch/bibSearchResultState';

const useBibSearchResultStateSetters = () => {
  const setBibSearchResultStateProperties = (newObject) => {
    const current = bibSearchResultVar();

    bibSearchResultVar({ ...current, ...newObject });
  };

  return {
    setBibSearchResultStateProperties,
  };
};

export { useBibSearchResultStateSetters };
