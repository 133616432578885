import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';

import { KeyboardDatePicker } from '@material-ui/pickers';

const SimpleSearchDateRangeValueInput = ({ fromValue, toValue, onChange }) => {
  const keyboardDatePickerProps = {
    autoOk: true,
    variant: 'inline',
    views: ['year'],
    inputVariant: 'outlined',
    margin: 'dense',
    animateYearScrolling: true,
    maxDate: new Date(),
    // minDate: new Date('1900-01-01'),
    error: false,
    helperText: null,
  };

  const fromChangeHandler = year => {
    const firstDayOfYear = year ? new Date(year, 0, 1) : null;
    onChange({ from: firstDayOfYear, to: toValue });
  };

  const toChangeHandler = year => {
    const lastDayOfYear = year ? new Date(year, 11, 31) : null;
    onChange({ from: fromValue, to: lastDayOfYear });
  };

  return (
    <Grid container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item>
        <KeyboardDatePicker
          {...keyboardDatePickerProps}
          value={fromValue ?? null}
          onChange={date => {
            const year = date?.getFullYear() || null;
            fromChangeHandler(year);
          }}
          // onChange nem hívódik meg ha manuálisan írsz be dátumot
          onBlur={e => {
            const year = e.target.value;
            fromChangeHandler(year);
          }}
        />
      </Grid>
      <Grid item>-</Grid>
      <Grid item>
        <KeyboardDatePicker
          {...keyboardDatePickerProps}
          value={toValue ?? null}
          onChange={date => {
            const year = date?.getFullYear() || null;
            toChangeHandler(year);
          }}
          // onChange nem hívódik meg ha manuálisan írsz be dátumot
          onBlur={e => {
            const year = e.target.value;
            toChangeHandler(year);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SimpleSearchDateRangeValueInput;
