import React from 'react';
// STYLE
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SimpleSearchSelectValueInput = ({
  searchField,
  searchValue,
  onChange,
}) => {
  const { values } = searchField;

  const options = Object.entries(values).map(option => {
    return {
      value: option[0],
      label: option[1],
    };
  });

  return (
    <Autocomplete
      fullWidth
      options={options ?? []}
      getOptionLabel={option => {
        return option.label;
      }}
      value={options.find(option => option.value === searchValue)}
      onChange={(event, newValue) => {
        onChange(newValue?.value);
      }}
      renderInput={params => (
        <TextField
          {...params}
          margin="dense"
          variant="outlined"
          style={{ backgroundColor: '#FFFFFF' }}
        />
      )}
    />
  );
};

export default SimpleSearchSelectValueInput;
