import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
// HOOKS
import { useBibSearchResultStateSetters } from '../../../../hooks/useBibSearchResultStateSetters';
// COMPONENTS
import SimpleSearchInputs from './SimpleSearchInputs/SimpleSearchInputs';
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../../MainSearch';
import { useQueryParams } from 'use-query-params';

const SimpleSearchTab = ({
  setAlreadySearch,
  urlState,
  setUrlState,
  searchClickedHandler,
}) => {
  const {
    setBibSearchResultStateProperties,
  } = useBibSearchResultStateSetters();

  const [, setDefaultUrlState] = useQueryParams(
    MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  );

  const onSearch = e => {
    e.preventDefault();
    setAlreadySearch(true);
    setDefaultUrlState(urlState);
    setBibSearchResultStateProperties({
      page: 0,
      filters: {},
      aggregations: {},
      loading: true,
      includedProfiles: [],
      includedIndexEntries: [],
      records: [],
      selectedRecords: [],
    });

    searchClickedHandler();
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <SimpleSearchInputs
          onSearch={onSearch}
          urlState={urlState}
          setUrlState={setUrlState}
        />
      </Grid>
    </Grid>
  );
};

export default SimpleSearchTab;
