import React, { useState, useEffect } from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
// // API
// import { getSearchFields } from 'api/mainSearch/simpleSearch';
// COMPONENTS
import ComplexSearchTableDedicatedInput from './ComplexSearchTableDedicatedInput/ComplexSearchTableDedicatedInput';

const ComplexSearchTabDedicatedInputs = props => {
  const [dedicatedInputs, setDedicatedInputs] = useState([]);

  // useEffect(() => {
  //   getSearchFields('&filter[isDedicatedQueryParam][equals]=true').then(
  //     (res) => {
  //
  //       setDedicatedInputs(res.data.nodes);
  //     }
  //   );
  // }, []);

  return (
    <Grid container alignItems="center">
      {dedicatedInputs.map(dedicatedInput => {
        return (
          <Grid item xs={6} key={dedicatedInput.id}>
            <ComplexSearchTableDedicatedInput dedicatedInput={dedicatedInput} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ComplexSearchTabDedicatedInputs;
