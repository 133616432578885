import React from 'react';
// COMPONENTS
import SimpleSearchStringValueInput from './SimpleSearchStringValueInput/SimpleSearchStringValueInput';
import SimpleSearchDateRangeValueInput from './SimpleSearchDateRangeValueInput/SimpleSearchDateRangeValueInput';
import SimpleSearchSelectValueInput from './SimpleSearchSelectValueInput/SimpleSearchSelectValueInput';
import SimpleSearchIndexEntryValueInput from './SimpleSearchIndexEntryValueInput/SimpleSearchIndexEntryValueInput';

const SimpleSearchValueInput = ({ searchField, searchValue, onChange }) => {
  if (!searchField) {
    return 'Kérem, válassza ki a keresési paramétert!';
  }

  switch (searchField.type) {
    case 'date-range':
      return (
        <SimpleSearchDateRangeValueInput
          fromValue={searchValue?.from}
          toValue={searchValue?.to}
          onChange={onChange}
        />
      );
    case 'select':
      return (
        <SimpleSearchSelectValueInput
          searchField={searchField}
          searchValue={searchValue}
          onChange={onChange}
        />
      );
    case 'index_entry':
      return (
        <SimpleSearchIndexEntryValueInput
          searchField={searchField}
          value={searchValue}
          onChange={onChange}
        />
      );
    default:
      return (
        <SimpleSearchStringValueInput
          searchValue={searchValue}
          onChange={onChange}
        />
      );
  }
};

export default SimpleSearchValueInput;
