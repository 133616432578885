import React, { useState } from 'react';
// import { complexSearchVar } from 'apollo/localState/mainSearch/complexSearchState';
// STYLE
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// API
import { GET_SEARCH_FIELDS } from '../../../../../../apollo/queries/searchRelatedQueries';
// HOOKS
import { useQuery } from '@apollo/client';
// COMPONENTS
// import ComplexSearchTabRowOperator from './ComplexSearchTabRowOperator/ComplexSearchTabRowOperator';
import SimpleSearchParamInput from '../../../SimpleSearchTab/SimpleSearchInputs/SimpleSearchParamInput/SimpleSearchParamInput';
import ComplexSearchTypeInput from '../../ComplexSearchTypeInput/ComplexSearchTypeInput';
import SimpleSearchValueInput from '../../../SimpleSearchTab/SimpleSearchInputs/SimpleSearchValueInput/SimpleSearchValueInput';
import { INPUT_TYPES_WITHOUT_SEARCH_TYPE } from '../../../../../../constants/complexSearch';

const ComplexSearchTabRow = ({ searchObject, urlState, setUrlState }) => {
  const { searchQuery } = urlState;
  const [searchParamSearchString, setSearchParamSearchString] = useState('');

  const { data, loading } = useQuery(GET_SEARCH_FIELDS, {
    variables: {
      searchString: searchParamSearchString,
    },
  });

  const rowDeletedHandler = () => {
    const newSearchQuery = searchQuery.filter(
      searchObj => searchObj.id !== searchObject.id
    );

    setUrlState(prevState => {
      return {
        ...prevState,
        searchQuery: newSearchQuery,
      };
    });
  };

  return (
    <Grid container alignItems="center" wrap="nowrap" spacing={1} className="searchRowContainer">
      {/* <Grid item xs={2}>
        <ComplexSearchTabRowOperator
          searchObject={searchObject}
          urlState={urlState}
          setUrlState={setUrlState}
        />
      </Grid> */}
      <Grid item xs={3}>
        <SimpleSearchParamInput
          setSearchParamSearchString={setSearchParamSearchString}
          searchParamOptions={data?.searchFields_v2?.nodes ?? []}
          paramAsyncLoading={loading}
          value={searchObject.searchField}
          onChange={newValue => {
            const newSearchQuery = searchQuery.map(searchObj => {
              if (searchObj.id !== searchObject.id) return searchObj;
              return {
                ...searchObj,
                searchField: newValue,
                searchValue:
                  newValue?.type === 'date-range' ? { from: '', to: '' } : '',
              };
            });

            setUrlState(prevState => {
              return {
                ...prevState,
                searchQuery: newSearchQuery,
              };
            });

            // setDefaultUrlState({
            //   searchQuery: JSON.stringify(newSearchQuery),
            // });
          }}
        />
      </Grid>
      {INPUT_TYPES_WITHOUT_SEARCH_TYPE.includes(
        searchObject?.searchField?.type
      ) ? null : (
        <Grid item xs={2}>
          <ComplexSearchTypeInput
            value={searchObject.type}
            onChange={e => {
              const newSearchQuery = searchQuery.map(searchObj => {
                if (searchObj.id !== searchObject.id) {
                  return searchObj;
                }
                return {
                  ...searchObj,
                  type: e.target.value,
                };
              });

              setUrlState(prevState => {
                return {
                  ...prevState,
                  searchQuery: newSearchQuery,
                };
              });

              // setDefaultUrlState({
              //   searchQuery: JSON.stringify(newSearchQuery),
              // });
            }}
          />
        </Grid>
      )}

      <Grid item xs={4}>
        <SimpleSearchValueInput
          searchField={searchObject.searchField}
          searchValue={searchObject.searchValue}
          onChange={newValue => {
            const newSearchQuery = searchQuery.map(searchObj => {
              if (searchObj.id !== searchObject.id) {
                return searchObj;
              }
              return {
                ...searchObj,
                searchValue: newValue,
              };
            });

            setUrlState(prevState => {
              return {
                ...prevState,
                searchQuery: newSearchQuery,
              };
            });

            // setDefaultUrlState({
            //   searchQuery: JSON.stringify(newSearchQuery),
            // });
          }}
        />
      </Grid>
      <Grid item xs={1} className="deleteRowIcon">
        <IconButton color="secondary" onClick={rowDeletedHandler}>
          <HighlightOffIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ComplexSearchTabRow;
