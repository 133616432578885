import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
// HOOKS
import { useQueryParams } from 'use-query-params';
//CONSTANTS
import {
  MAIN_SEARCH_URL_STATE_DEFAULT_VALUE,
  searchQueryDefaultValue,
} from '../../../../MainSearch';
//UTILS
import { v4 as uuid } from 'uuid';
import { useLazyQuery } from '@apollo/client';
import { GET_SEARCH_FIELDS } from '../../../../../../apollo/queries/searchRelatedQueries';

const ComplexSearchTabRowsOperationButtons = ({ urlState, setUrlState }) => {
  // const [_, setDefaultUrlState] = useQueryParams(
  //   MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  // );

  const [getSearchFields] = useLazyQuery(GET_SEARCH_FIELDS, {
    onCompleted: data => {
      const titleSearchField = data.searchFields_v2.nodes.find(
        ({ slug }) => slug === 'main-title'
      );
      const authorSearchField = data.searchFields_v2.nodes.find(
        ({ slug }) => slug === 'author'
      );
      const subjectSearchField = data.searchFields_v2.nodes.find(
        ({ slug }) => slug === 'subject'
      );

      const newSearchQuery = [
        {
          id: 'firstRow',
          type: 'begin_with',
          searchField: titleSearchField,
          searchValue: '',
        },
        {
          id: uuid(),
          operator: 'and',
          type: 'begin_with',
          searchField: authorSearchField,
          searchValue: '',
        },
        {
          id: uuid(),
          operator: 'and',
          type: 'begin_with',
          searchField: subjectSearchField,
          searchValue: '',
        },
      ];

      setUrlState(prevUrlState => {
        return {
          ...prevUrlState,
          searchQuery: newSearchQuery,
        };
      });
      // setDefaultUrlState({ searchQuery: JSON.stringify(newSearchQuery) });
    },
  });

  const newRowAddedHandler = () => {
    const { searchQuery } = urlState;

    const newSearchQuery = [
      ...searchQuery,
      {
        id: uuid(),
        operator: 'and',
        type: 'begin_with',
        searchField: '',
        searchValue: '',
      },
    ];

    setUrlState(prevUrlState => {
      return {
        ...prevUrlState,
        searchQuery: newSearchQuery,
      };
    });

    // setDefaultUrlState({
    //   searchQuery: JSON.stringify(newSearchQuery),
    // });
  };

  const resetClickedHandler = () => {
    getSearchFields();
  };

  return (
    <Grid container alignItems="center" wrap="nowrap" spacing={2}>
      <Grid item>
        <Button onClick={newRowAddedHandler} size="small" color="primary">
          <Grid container spacing={1} className="searchTabButton">
            <Grid item>
              <AddCircleIcon />
            </Grid>
            <Grid item>Új sor hozzáadása</Grid>
          </Grid>
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={resetClickedHandler} size="small" color="secondary">
          <Grid container spacing={1} className="searchTabButton">
            <Grid item>
              <RotateLeftIcon />
            </Grid>
            <Grid item>Visszaállítás</Grid>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ComplexSearchTabRowsOperationButtons;
