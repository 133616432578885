import React from 'react';
// STYLE
import TextField from '@material-ui/core/TextField';

const SimpleSearchStringValueInput = ({ searchValue, onChange }) => {
  return (
    <TextField
      value={searchValue}
      onChange={e => onChange(e.target.value)}
      variant="outlined"
      margin="dense"
      fullWidth
      label="Keresőkifejezés"
      style={{ backgroundColor: '#FFFFFF' }}
      InputLabelProps={{
        shrink: searchValue && searchValue !== '',
      }}
    />
  );
};

export default SimpleSearchStringValueInput;
