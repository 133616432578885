import React, { useState } from 'react';
// HOOKS
import { NumberParam, JsonParam, StringParam } from 'use-query-params';
// COMPONENTS
import MainSearchTabs from '../../components/MainSearch/MainSearchTabs/MainSearchTabs';

export const MAIN_SEARCH_URL_STATE_DEFAULT_VALUE = {
  tabValue: NumberParam,
  itemPerPage: NumberParam,
  view: StringParam,
  sort: StringParam,
  searchField: JsonParam,
  searchValue: JsonParam,
  searchQuery: JsonParam,
};

export const searchQueryDefaultValue = [];

const MainSearch = ({ urlState, setUrlState, searchClickedHandler }) => {
  const [alreadySearch, setAlreadySearch] = useState(false);

  return (
    <MainSearchTabs
      urlState={urlState}
      setUrlState={setUrlState}
      setAlreadySearch={setAlreadySearch}
      searchClickedHandler={searchClickedHandler}
    />
  );
};

export default MainSearch;
