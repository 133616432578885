import React from 'react';
// STYLE
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';

const ComplexSearchTypeInput = ({ value, onChange }) => {
  const options = [
    {
      id: 'exact',
      label: 'Pontos egyezés',
    },
    {
      id: 'contains',
      label: 'Tartalmazza',
    },
    {
      id: 'begin_with',
      label: 'Kezdődik',
    },
  ];

  return (
    <TextField
      value={value}
      onChange={onChange}
      variant="outlined"
      margin="dense"
      fullWidth
      style={{ backgroundColor: '#FFFFFF' }}
      select
    >
      {options.map(option => {
        const { id, label } = option;
        return (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default ComplexSearchTypeInput;
