import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
// COMPONENTS
import ComplexSearchTabRepresentedBlockRowElement from './ComplexSearchTabRepresentedBlockRowElement/ComplexSearchTabRepresentedBlockRowElement';

const ComplexSearchTabRepresentedBlockRow = ({
  values,
  representedBlockIndex,
  urlState,
  setUrlState,
}) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      {values.map((searchObject, rowIndex) => {
        const { id } = searchObject;
        return (
          <Grid item key={id}>
            <ComplexSearchTabRepresentedBlockRowElement
              searchObject={searchObject}
              representedBlockIndex={representedBlockIndex}
              rowIndex={rowIndex}
              urlState={urlState}
              setUrlState={setUrlState}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ComplexSearchTabRepresentedBlockRow;
