import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { useBibSearchResultStateSetters } from '../../../../hooks/useBibSearchResultStateSetters';
// COMPONENTS
import ComplexSearchTabDedicatedInputs from './ComplexSearchTabDedicatedInputs/ComplexSearchTabDedicatedInputs';
import ComplexSearchTabRows from './ComplexSearchTabRows/ComplexSearchTabRows';
import ComplexSearchTabRepresentBlock from './ComplexSearchTabRepresentBlock/ComplexSearchTabRepresentBlock';
import { useQueryParams } from 'use-query-params';
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../../MainSearch';

const ComplexSearchTab = ({
  setAlreadySearch,
  urlState,
  setUrlState,
  searchClickedHandler,
}) => {
  const {
    setBibSearchResultStateProperties,
  } = useBibSearchResultStateSetters();

  const [, setDefaultUrlState] = useQueryParams(
    MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  );

  const onComplexSearch = e => {
    e.preventDefault();
    setAlreadySearch(true);
    setDefaultUrlState(urlState);
    setBibSearchResultStateProperties({
      page: 0,
      filters: {},
      aggregations: {},
      loading: true,
      includedProfiles: [],
      includedIndexEntries: [],
      records: [],
      selectedRecords: [],
    });

    searchClickedHandler();
  };

  return (
    <form onSubmit={onComplexSearch}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <ComplexSearchTabDedicatedInputs />
        </Grid>
        <Grid item>
          <ComplexSearchTabRows urlState={urlState} setUrlState={setUrlState} />
        </Grid>
        <Grid item>
          <ComplexSearchTabRepresentBlock
            urlState={urlState}
            setUrlState={setUrlState}
          />
        </Grid>
        <Button color="primary" type="submit">
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={1} className="searchTabButton">
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>Keresés</Grid>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </form>
  );
};

export default ComplexSearchTab;
