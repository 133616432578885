import React, { useState, useEffect, useRef } from 'react';
// STYLE
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// API
import { GET_INDEX_ENTRIES } from './query';
// HOOKS
import { useQuery } from '@apollo/client';

const SimpleSearchIndexEntryValueInput = ({ searchField, value, onChange }) => {
  const timer = useRef(null);
  const { values } = searchField;
  const [searchString, setSearchString] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const { data, loading } = useQuery(GET_INDEX_ENTRIES, {
    variables: {
      where: {
        vocabularies_some: {
          guid_in: [values.vocabulary_guid_id],
        },
        simpleSearchString: searchString,
      },
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setOptions(
      data?.simpleSearchIndexEntries?.map(indexEntry => {
        const { guid, generatedDisplayName } = indexEntry;

        return {
          value: guid,
          label: generatedDisplayName,
        };
      })
    );
  }, [data]);

  const searchStringChangedhandler = e => {
    e.persist();
    clearTimeout(timer.current);
    setInputValue(e.target.value);
    timer.current = setTimeout(() => {
      setSearchString(e.target.value);
    }, 700);
  };

  return (
    <Autocomplete
      fullWidth
      disabled={loading}
      options={options}
      getOptionLabel={option => {
        return option?.label ?? '';
      }}
      value={options.find(option => option.label === value)}
      onChange={(event, newValue) => {
        onChange(newValue?.label);
      }}
      renderInput={params => (
        <TextField
          {...params}
          style={{ backgroundColor: '#FFFFFF' }}
          margin="dense"
          value={inputValue}
          variant="outlined"
          onChange={searchStringChangedhandler}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SimpleSearchIndexEntryValueInput;
