import React, { useState } from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
// API
import { GET_SEARCH_FIELDS } from '../../../../../../../../apollo/queries/searchRelatedQueries';
// HOOKS
import { useQuery } from '@apollo/client';
import { useQueryParams } from 'use-query-params';
// CONSTANTS
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../../../../../../MainSearch';
import { INPUT_TYPES_WITHOUT_SEARCH_TYPE } from '../../../../../../../../constants/complexSearch';
// COMPONENTS
import SimpleSearchParamInput from '../../../../../SimpleSearchTab/SimpleSearchInputs/SimpleSearchParamInput/SimpleSearchParamInput';
import ComplexSearchTypeInput from '../../../../ComplexSearchTypeInput/ComplexSearchTypeInput';
import SimpleSearchValueInput from '../../../../../SimpleSearchTab/SimpleSearchInputs/SimpleSearchValueInput/SimpleSearchValueInput';
import ComplexSearchTabRowOperator from '../../../../ComplexSearchTabRows/ComplexSearchTabRow/ComplexSearchTabRowOperator/ComplexSearchTabRowOperator';

const ComplexSearchTabRepresentedBlockRowElement = ({
  searchObject,
  representedBlockIndex,
  rowIndex,
  urlState,
  setUrlState,
}) => {
  const [_, setDefaultUrlState] = useQueryParams(
    MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  );
  const { searchQuery } = urlState;
  const [searchParamSearchString, setSearchParamSearchString] = useState('');

  const { data, loading } = useQuery(GET_SEARCH_FIELDS, {
    variables: {
      searchString: searchParamSearchString,
    },
  });

  // useEffect(() => {
  //   const additionalUrl = searchParamSearchString
  //     ? `&filter[title][contains]=${searchParamSearchString}`
  //     : null;

  //   setParamAsyncLoading(true);

  //   getSearchFields(additionalUrl)
  //     .then((res) => {
  //       console.log('res', res);
  //       setSearchParamOptions(res?.data?.nodes ?? []);
  //       setParamAsyncLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //       setParamAsyncLoading(false);
  //     });
  // }, [searchParamSearchString]);

  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      {representedBlockIndex === 0 && rowIndex === 0 ? null : (
        <Grid item>
          <ComplexSearchTabRowOperator
            searchObject={searchObject}
            urlState={urlState}
            setUrlState={setUrlState}
          />
        </Grid>
      )}
      <Grid item>
        <SimpleSearchParamInput
          setSearchParamSearchString={setSearchParamSearchString}
          searchParamOptions={data?.searchFields_v2?.nodes ?? []}
          paramAsyncLoading={loading}
          value={searchObject.searchField}
          onChange={newValue => {
            const newSearchQuery = searchQuery.map(searchObj => {
              if (searchObj.id !== searchObject.id) return searchObj;
              return {
                ...searchObj,
                searchField: newValue,
                searchValue:
                  newValue?.type === 'date-range' ? { from: '', to: '' } : '',
              };
            });

            setUrlState(prevState => {
              return {
                ...prevState,
                searchQuery: newSearchQuery,
              };
            });

            setDefaultUrlState({
              searchQuery: JSON.stringify(newSearchQuery),
            });
          }}
        />
      </Grid>
      {INPUT_TYPES_WITHOUT_SEARCH_TYPE.includes(
        searchObject?.searchField?.type
      ) ? null : (
        <Grid item>
          <ComplexSearchTypeInput
            value={searchObject.type}
            onChange={e => {
              const newSearchQuery = searchQuery.map(searchObj => {
                if (searchObj.id !== searchObject.id) {
                  return searchObj;
                }
                return {
                  ...searchObj,
                  type: e.target.value,
                };
              });

              setUrlState(prevState => {
                return {
                  ...prevState,
                  searchQuery: newSearchQuery,
                };
              });

              setDefaultUrlState({
                searchQuery: JSON.stringify(newSearchQuery),
              });
            }}
          />
        </Grid>
      )}

      <Grid item>
        <SimpleSearchValueInput
          searchField={searchObject.searchField}
          searchValue={searchObject.searchValue}
          onChange={newValue => {
            const newSearchQuery = searchQuery.map(searchObj => {
              if (searchObj.id !== searchObject.id) {
                return searchObj;
              }
              return {
                ...searchObj,
                searchValue: newValue,
              };
            });

            setUrlState(prevState => {
              return {
                ...prevState,
                searchQuery: newSearchQuery,
              };
            });

            setDefaultUrlState({
              searchQuery: JSON.stringify(newSearchQuery),
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ComplexSearchTabRepresentedBlockRowElement;
