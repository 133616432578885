import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
import ComplexSearchTabRepresentedBlockRow from './ComplexSearchTabRepresentedBlockRow/ComplexSearchTabRepresentedBlockRow';

const ComplexSearchTabRepresentBlockRows = ({
  complexSearchRepresentedBlocks,
  urlState,
  setUrlState,
}) => {
  return (
    <Grid container direction="column">
      {complexSearchRepresentedBlocks.map(
        (representedBlock, representedBlockIndex) => {
          const { id, values } = representedBlock;

          return (
            <Grid item key={id}>
              <ComplexSearchTabRepresentedBlockRow
                values={values}
                representedBlockIndex={representedBlockIndex}
                urlState={urlState}
                setUrlState={setUrlState}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default ComplexSearchTabRepresentBlockRows;
