import React from 'react';
// COMPONENTS
import ComplexSearchTabRepresentBlockRows from './ComplexSearchTabRepresentBlockRows/ComplexSearchTabRepresentBlockRows';

const ComplexSearchTabRepresentBlock = ({ urlState, setUrlState }) => {
  const { searchQuery } = urlState;

  const complexSearchRepresentedBlocks = searchQuery.reduce(
    (acc, searchObject, i) => {
      if (i === 0 || searchObject.operator === 'or') {
        return [...acc, { id: searchObject.id, values: [searchObject] }];
      }

      return acc.map((searchArrayObject, i) => {
        if (i !== acc.length - 1) {
          return searchArrayObject;
        }

        return {
          ...searchArrayObject,
          values: [...searchArrayObject.values, searchObject],
        };
      });
    },
    []
  );

  return (
    <ComplexSearchTabRepresentBlockRows
      complexSearchRepresentedBlocks={complexSearchRepresentedBlocks}
      urlState={urlState}
      setUrlState={setUrlState}
    />
  );
};

export default ComplexSearchTabRepresentBlock;
