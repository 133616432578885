import React, { useState } from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// API
import { GET_SEARCH_FIELDS } from '../../../../../apollo/queries/searchRelatedQueries';
// HOOKS
import { useQueryParams } from 'use-query-params';
import { useQuery } from '@apollo/client';
// CONSTANTS
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../../../MainSearch';
// COMPONENTS
import SimpleSearchParamInput from './SimpleSearchParamInput/SimpleSearchParamInput';
import SimpleSearchValueInput from './SimpleSearchValueInput/SimpleSearchValueInput';

const SimpleSearchInputs = ({
  state,
  dispatch,
  onSearch,
  urlState,
  setUrlState,
}) => {
  const [searchParamSearchString, setSearchParamSearchString] = useState('');

  // const [_, setDefaultUrlState] = useQueryParams(
  //   MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  // );

  const { searchField, searchValue } = urlState;

  const { data, loading } = useQuery(GET_SEARCH_FIELDS, {
    variables: {
      searchString: searchParamSearchString,
    },
  });

  return (
    <form onSubmit={onSearch}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <SimpleSearchParamInput
            setSearchParamSearchString={setSearchParamSearchString}
            searchParamOptions={data?.searchFields_v2?.nodes ?? []}
            paramAsyncLoading={loading}
            value={searchField}
            onChange={newValue => {
              // setDefaultUrlState({
              //   searchField: JSON.stringify(newValue),
              //   searchValue: JSON.stringify({
              //     value:
              //       newValue?.type === 'date-range' ? { from: '', to: '' } : '',
              //   }),
              // });

              setUrlState(prevState => {
                return {
                  ...prevState,
                  searchField: newValue,
                  searchValue: {
                    value:
                      newValue?.type === 'date-range'
                        ? { from: '', to: '' }
                        : '',
                  },
                };
              });
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <SimpleSearchValueInput
            searchField={searchField}
            searchValue={searchValue?.value ?? ''}
            onChange={newValue => {
              // setDefaultUrlState({
              //   searchValue: JSON.stringify({
              //     value: newValue,
              //   }),
              // });

              setUrlState(prevState => {
                return {
                  ...prevState,
                  searchValue: {
                    value: newValue,
                  },
                };
              });
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <Button color="primary" type="submit">
            Keresés
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SimpleSearchInputs;
