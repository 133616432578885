import React from 'react';
import { bibSearchResultVar } from '../../../apollo/localState/mainSearch/bibSearchResultState';
// STYLE
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel/TabPanel';
// HOOKS
import { useReactiveVar } from '@apollo/client';
import { useQueryParams } from 'use-query-params';
//CONSTANTS
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../MainSearch';
// COMPONENTS
import SimpleSearchTab from './SimpleSearchTab/SimpleSearchTab';
import ComplexSearchTab from './ComplexSearchTab/ComplexSearchTab';
import { mainSearchReducerActions } from '../reducer';

const MainSearchTabs = ({
  urlState,
  setUrlState,
  setAlreadySearch,
  searchClickedHandler,
}) => {
  const classes = useStyles();
  const bibSearchResultState = useReactiveVar(bibSearchResultVar);
  const { recordCount } = bibSearchResultState;
  const { tabValue } = urlState;

  // const [_, setDefaultUrlState] = useQueryParams(
  //   MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  // );

  const onChangeTab = (e, newValue) => {
    setUrlState(prevUrlState => ({
      ...prevUrlState,
      tabValue: newValue,
    }));
    // setDefaultUrlState({
    //   tabValue: newValue,
    // });
  };

  const tabs = [
    {
      id: 'simpleSearch',
      label: 'Egyszerű kereső',
      component: (
        <SimpleSearchTab
          setAlreadySearch={setAlreadySearch}
          urlState={urlState}
          setUrlState={setUrlState}
          searchClickedHandler={searchClickedHandler}
        />
      ),
    },
    {
      id: 'complexSearch',
      label: 'Összetett kereső',
      component: (
        <ComplexSearchTab
          setAlreadySearch={setAlreadySearch}
          urlState={urlState}
          setUrlState={setUrlState}
          searchClickedHandler={searchClickedHandler}
        />
      ),
    },
  ];

  return (
    <Paper
      className={classes.root}
      style={{
        // maxWidth: tabValue === 0 ? 900 : 1600,
        maxWidth: tabValue === 0 ? 1260 : 1280,
        marginTop: recordCount ? 0 : '2rem',
        boxShadow: 'none',
        marginTop: '30px',
      }}
    >
      <Tabs
        value={tabValue ? tabValue : 0}
        onChange={onChangeTab}
        indicatorColor="primary"
        // textColor="primary"
        textColor="#000000"
        style={{ backgroundColor: '#f1f1f1' }}
        centered
      >
        {tabs.map(tab => {
          const { id, label } = tab;

          return <Tab key={id} label={label} />;
        })}
      </Tabs>
      {tabs.map((tab, i) => {
        const { id, component } = tab;

        return (
          <TabPanel key={id} value={tabValue ? tabValue : 0} index={i}>
            {component}
          </TabPanel>
        );
      })}
    </Paper>
  );
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: 'auto',
  },
});

export default MainSearchTabs;
