import { gql } from '@apollo/client';

export const GET_INDEX_ENTRIES = gql`
  query simpleSearchIndexEntries($where: IndexEntrySimpleSearchInput) {
    simpleSearchIndexEntries(where: $where) {
      guid
      generatedDisplayName
    }
  }
`;
