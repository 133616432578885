import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
// CONSTNTS
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../../../../MainSearch';
import { useQueryParams } from 'use-query-params';

const ComplexSearchTabOperationRadioButtons = ({
  searchObject,
  urlState,
  setUrlState,
}) => {
  const { operator } = searchObject;
  // const [_, setDefaultUrlState] = useQueryParams(
  //   MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  // );

  const handleChange = e => {
    const newValue = e.target.value ?? 'and';
    const newSearchQuery = urlState.searchQuery.map(searchObj => {
      if (searchObject.id !== searchObj.id) {
        return searchObj;
      }

      return {
        ...searchObj,
        operator: newValue,
      };
    });
    // setDefaultUrlState({ searchQuery: JSON.stringify(newSearchQuery) });
    setUrlState(prevState => {
      return {
        ...prevState,
        searchQuery: newSearchQuery,
      };
    });
  };

  return (
    <Grid container alignItems="center" spacing={8} className="relationSelect">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Radio
              value="and"
              checked={operator === 'and'}
              onChange={handleChange}
              color="primary"
            />
          </Grid>
          <Grid item>
            <Typography>És</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Radio
              value="or"
              checked={operator === 'or'}
              onChange={handleChange}
              color="primary"
            />
          </Grid>
          <Grid item>
            <Typography>Vagy</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Radio
              value="not"
              checked={operator === 'not'}
              onChange={handleChange}
              color="primary"
            />
          </Grid>
          <Grid item>
            <Typography>Nem</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComplexSearchTabOperationRadioButtons;
