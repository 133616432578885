import React from 'react';
// import { complexSearchVar } from 'apollo/localState/mainSearch/complexSearchState';
// STYLE
import MenuItem from '@material-ui/core/MenuItem';
// HOOKS
import { useQueryParams } from 'use-query-params';
// CONSTANTS
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../../../../../MainSearch';
import { TextField } from '@material-ui/core';

const ComplexSearchTabRowOperator = ({
  searchObject,
  urlState,
  setUrlState,
}) => {
  const { operator = 'and' } = searchObject;
  const [_, setDefaultUrlState] = useQueryParams(
    MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  );

  const onChange = e => {
    const { searchQuery } = urlState;

    const newSearchQuery = searchQuery.map(searchObj => {
      if (searchObj.id !== searchObject.id) {
        return searchObj;
      }
      return {
        ...searchObj,
        operator: e.target.value,
      };
    });

    setUrlState(prevState => {
      return {
        ...prevState,
        searchQuery: newSearchQuery,
      };
    });

    setDefaultUrlState({ searchQuery: JSON.stringify(newSearchQuery) });
  };

  return (
    <TextField
      value={operator}
      onChange={onChange}
      fullWidth
      variant="outlined"
      margin="dense"
      style={{ backgroundColor: '#FFFFFF' }}
      select
    >
      <MenuItem value="and">És</MenuItem>
      <MenuItem value="or">Vagy</MenuItem>
      <MenuItem value="not">Nem</MenuItem>
    </TextField>
  );
};

export default ComplexSearchTabRowOperator;
