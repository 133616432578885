import React, { useState, useRef } from 'react';
// STYLE
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const SimpleSearchParamInput = ({
  setSearchParamSearchString,
  searchParamOptions,
  paramAsyncLoading,
  value,
  onChange,
}) => {
  const timer = useRef(null);
  const [inputValue, setInputValue] = useState('');

  const searchParamSearchStringChangedHandler = e => {
    e.persist();
    clearTimeout(timer.current);
    setInputValue(e.target.value);
    timer.current = setTimeout(() => {
      setSearchParamSearchString(e.target.value);
    }, 700);
  };

  return (
    <Autocomplete
      fullWidth
      disabled={paramAsyncLoading}
      options={searchParamOptions}
      getOptionLabel={option => {
        return option.title ?? '';
      }}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          style={{ backgroundColor: '#FFFFFF' }}
          margin="dense"
          value={inputValue}
          variant="outlined"
          onChange={searchParamSearchStringChangedHandler}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {paramAsyncLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SimpleSearchParamInput;
